// Generated by Framer (d31cd55)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {BGGwxPnqd: {hover: true}};

const serializationHash = "framer-tdKQK"

const variantClassNames = {BGGwxPnqd: "framer-v-1f3w0wa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, text, width, ...props}) => { return {...props, DDxhkHmp5: text ?? props.DDxhkHmp5 ?? "See All Courses"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DDxhkHmp5, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "BGGwxPnqd", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1f3w0wa", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"BGGwxPnqd"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"BGGwxPnqd-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7QXJjaGl2by1tZWRpdW0=", "--framer-font-family": "\"Archivo\", \"Archivo Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "500", "--framer-line-height": "1.6em"}}>See All Courses</motion.p></React.Fragment>} className={"framer-12hsqn1"} fonts={["FS;Archivo-medium"]} layoutDependency={layoutDependency} layoutId={"OAdl7xaXg"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={DDxhkHmp5} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><motion.div className={"framer-15gi4ky-container"} layoutDependency={layoutDependency} layoutId={"QJvXXADNJ-container"}><Phosphor color={"rgb(0, 0, 0)"} height={"100%"} iconSearch={"arrow right"} iconSelection={"House"} id={"QJvXXADNJ"} layoutId={"QJvXXADNJ"} mirrored={false} selectByList={false} style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tdKQK.framer-pcnssf, .framer-tdKQK .framer-pcnssf { display: block; }", ".framer-tdKQK.framer-1f3w0wa { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; height: min-content; justify-content: space-between; overflow: visible; padding: 0px; position: relative; width: 172px; }", ".framer-tdKQK .framer-12hsqn1 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-tdKQK .framer-15gi4ky-container { flex: none; height: 20px; position: absolute; right: 0px; top: calc(50.00000000000002% - 20px / 2); width: 20px; z-index: 1; }", ".framer-tdKQK.framer-v-1f3w0wa.hover .framer-15gi4ky-container { right: -8px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 172
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"p0OntWkEA":{"layout":["fixed","auto"]}}}
 * @framerVariables {"DDxhkHmp5":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameraXfys6wK2: React.ComponentType<Props> = withCSS(Component, css, "framer-tdKQK") as typeof Component;
export default FrameraXfys6wK2;

FrameraXfys6wK2.displayName = "see all btn";

FrameraXfys6wK2.defaultProps = {height: 32, width: 172};

addPropertyControls(FrameraXfys6wK2, {DDxhkHmp5: {defaultValue: "See All Courses", displayTextArea: false, title: "text", type: ControlType.String}} as any)

addFonts(FrameraXfys6wK2, [{explicitInter: true, fonts: [{family: "Archivo", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/5ISYWGR43XD57J2U5VEYATNUZO57OCPH/UIED7ZBTCD42AJ7GJT5NWKWHCOVDRNAP/EPHSF4UBJRLGUILEFJVCMY7ET4W5HPUF.woff2", weight: "500"}]}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})